import { actionTypes } from "QuorumGrassroots/campaign-forms/action-types"
import { fromJS, List, Map } from "immutable"
import { insertMessageEditablePlaceholders } from "QuorumGrassroots/helperFunctions.js"
import { replaceSupporterPlaceholderOnTargetedMessages } from "QuorumGrassroots/helpers"

const initialState = Map()

const OFFICIALS_PREVIEW = "officialsPreview"
const TARGETED_MESSAGES = "targetedMessages"
const SUPPORTER_PLACEHOLDERS = "supporterPlaceholders"

const handlers = {
    [actionTypes.SIMPLE_GET_CAMPAIGN_MESSAGES_SUCCESS](state, action) {
        return state
            .setIn([action.uniqueWidgetId, "messages"], fromJS(action.response.data.messages))
            .setIn([action.uniqueWidgetId, "extraMessageData"], fromJS(action.response.data.extra_message_data))
    },

    [actionTypes.GET_CAMPAIGN_MESSAGES_START](state, action) {
        return state.setIn([action.uniqueWidgetId, "messagesLoading"], true)
    },

    [actionTypes.GET_CAMPAIGN_MESSAGES_SUCCESS](state, action) {
        const messages = action.response.data.messages
        const oneClickEditedMessages = action.oneClickEditedMessages
        // now that we have the requisite information from the backend,
        // let's add a few more fields into each official's "message" object.
        // these fields are to keep track of which messages we presently are
        // on.
        const messageSlices = messages.reduce((acc, message, idx) => {
            const messageId = action.getIdFromMessage(message)

            message = insertMessageEditablePlaceholders(message)

            const messageFormName = message.group?.id ? String(message.group.id) : "global"

            // this overrides the fetched messages with the ones from the one click registration with the user edits
            if (oneClickEditedMessages?.get("values")?.size) {
                const body = oneClickEditedMessages.getIn(["values", messageFormName, "body"])
                message.subject = oneClickEditedMessages.getIn(["values", messageFormName, "subject"])
                message.bodies = [body]
                if (oneClickEditedMessages.getIn(["values", "global", "submitting_org_name"]) !== undefined) {
                    message.submitting_org_name = oneClickEditedMessages.getIn([
                        "values",
                        "global",
                        "submitting_org_name",
                    ])
                }
            }

            const editSlice = { editedBodies: message.bodies || [], currentBodyIdx: 0, targeted: true }
            const modifiedMessage = { ...message, order: idx, edits: editSlice }

            // Here we want to make sure a legislator receives only one message even if they are in multiple groups
            // We are adding it to the first group they are in
            if (!acc[messageId]) {
                acc[messageId] = modifiedMessage
            }

            return acc
        }, {})

        const activeSelect = messages.length && action.getIdFromMessage(messages[0])

        const activeGroup = messages.length > 0 && messages[0].group ? messages[0].group.id.toString() : 0

        return state
            .setIn([action.uniqueWidgetId, "messages"], fromJS(messageSlices))
            .setIn([action.uniqueWidgetId, "extraMessageData"], fromJS(action.response.data.extra_message_data))
            .setIn([action.uniqueWidgetId, "activeSelect"], activeSelect)
            .setIn([action.uniqueWidgetId, "activeGroup"], activeGroup)
            .setIn([action.uniqueWidgetId, "messagesLoading"], false)
            .setIn([action.uniqueWidgetId, "messagesLoaded"], true)
            .setIn([TARGETED_MESSAGES], fromJS(action.response.data.extra_message_data))
    },

    [actionTypes.RESET_WRITE_CAMPAIGN_SLICE](state, action) {
        return state.setIn([action.uniqueWidgetId, "sentIds"], List()).withMutations((store) => {
            state.getIn([action.uniqueWidgetId, "messages"]).forEach((message, key) => {
                store.setIn(
                    [action.uniqueWidgetId, "messages", key, "edits"],
                    fromJS({
                        editedBodies: message.get("bodies"),
                        currentBodyIdx: 0,
                        targeted: true,
                    }),
                )
            })
        })
    },

    [actionTypes.CHANGE_MESSAGE_GROUP](state, action) {
        return state.setIn([action.uniqueWidgetId, "activeGroup"], action.groupId)
    },

    [actionTypes.CHANGE_TARGET](state, action) {
        return state.setIn([action.uniqueWidgetId, "activeSelect"], action.selectedId)
    },

    [actionTypes.TOGGLE_TARGET_ONE_CLICK](state, action) {
        return state.withMutations((store) => {
            const officials = store.get(OFFICIALS_PREVIEW).toJS()
            const targetedOfficials = []
            const untargetedOfficials = []

            officials.forEach((official) => {
                if (official.message_group_id !== action.groupId) {
                    targetedOfficials.push(official)
                    return
                }

                if (action.targetId.includes(official.value)) {
                    official.targeted = true
                    targetedOfficials.push(official)
                } else {
                    official.targeted = false
                    untargetedOfficials.push(official)
                }
            })

            const newOfficials = [...targetedOfficials, ...untargetedOfficials]
            store.set(OFFICIALS_PREVIEW, fromJS(newOfficials))
        })
    },

    [actionTypes.TOGGLE_TARGET](state, action) {
        //There's no need for a new reducer/action type for the multiselect since this one is only used on the
        //RecipientSelectorTable, which will be deprecated after we launch the new UI
        return state.withMutations((store) => {
            const messages = state.getIn([action.uniqueWidgetId, "messages"])
            const hasMessageGroups = messages.some((message) => message.get("group"))
            const isCombinedCampaign =
                store.getIn([action.uniqueWidgetId, "content", "campaign_type"]) ===
                DjangIO.app.grassroots.campaign.types.CampaignType.combined_campaign.value
            messages
                .filter(
                    (message) =>
                        !hasMessageGroups || //if there are no message groups we use all the target
                        isCombinedCampaign || //even if there are message groups, combined campaigns don't have them currently so we use all the targets
                        Number(message.getIn(["group", "id"])) ===
                            Number(state.getIn([action.uniqueWidgetId, "activeGroup"])),
                )
                .forEach((message, messageId) => {
                    if (
                        //logic for combined campaigns: skip non-wal campaigns and custom targets
                        messageId.endsWith("_") || //this will skip non-wal campaigns, which have the id in the format campaignId_
                        messageId.includes("custom") //this will skip custom targets, which have the id as campaignId_custom-randomNumber
                    ) {
                        return
                    }
                    store.setIn([action.uniqueWidgetId, "messages", messageId, "edits", "targeted"], false)
                })
            action.targetId.forEach((id) => {
                store.setIn([action.uniqueWidgetId, "messages", id, "edits", "targeted"], true)
            })
        })
    },

    [actionTypes.SELECT_ALL_TARGETS](state, action) {
        return state.withMutations((store) => {
            state.getIn([action.uniqueWidgetId, "messages"]).forEach((_message, key) => {
                store.setIn([action.uniqueWidgetId, "messages", key, "edits", "targeted"], true)
            })
        })
    },

    [actionTypes.DESELECT_ALL_TARGETS](state, action) {
        return state.withMutations((store) => {
            state.getIn([action.uniqueWidgetId, "messages"]).forEach((_message, key) => {
                store.setIn([action.uniqueWidgetId, "messages", key, "edits", "targeted"], false)
            })
        })
    },

    [actionTypes.SUBMIT_CAMPAIGN_MESSAGES_SUCCESS](state) {
        return state
    },

    [actionTypes.UPDATE_SENT_IDS](state, action) {
        return state.updateIn([action.uniqueWidgetId, "sentIds"], List(), (sentIds) => sentIds.concat(action.sentIds))
    },

    [actionTypes.STORE_CURRENT_MESSAGE](state, action) {
        const idx = state.getIn([action.uniqueWidgetId, "messages", action.currentId, "edits", "currentBodyIdx"])

        return state.updateIn(
            [action.uniqueWidgetId, "messages", action.currentId, "edits", "editedBodies"],
            (editedBodies) => editedBodies.set(idx, action.message),
        )
    },

    [actionTypes.VIEW_NEXT_MESSAGE](state, action) {
        const idx = state.getIn([action.uniqueWidgetId, "messages", action.currentId, "edits", "currentBodyIdx"])
        const messageSize = state.getIn([
            action.uniqueWidgetId,
            "messages",
            action.currentId,
            "edits",
            "editedBodies",
        ]).size

        const incrementedIdx = idx + action.increment

        if (incrementedIdx < 0 || incrementedIdx >= messageSize) {
            console.warn(`No more messages for increment ${action.increment}!`)
            return state
        }

        return state.updateIn(
            [action.uniqueWidgetId, "messages", action.currentId, "edits", "currentBodyIdx"],
            () => incrementedIdx,
        )
    },

    [actionTypes.POST_GRASSROOTS_SUPPORTER_ACTION_START](state, action) {
        return state.setIn([action.uniqueWidgetId, "isSubmitting"], true)
    },

    [actionTypes.POST_GRASSROOTS_SUPPORTER_ACTION_SUCCESS](state, action) {
        return state.setIn([action.uniqueWidgetId, "isSubmitting"], false)
    },

    [actionTypes.POST_GRASSROOTS_SUPPORTER_ACTION_FAIL](state, action) {
        return state.setIn([action.uniqueWidgetId, "isSubmitting"], false)
    },

    [actionTypes.CALL_OFFICE_START](state, action) {
        // we started the call. Time to start the "calling..."
        // thing.
        return state.setIn([action.uniqueWidgetId, "messages", action.messageKey, "edits", "isCalling"], true)
    },

    [actionTypes.CALL_OFFICE_SUCCESS](state, action) {
        // now that the twilio client is happily running away
        // and has created a grassroots action, store that action / whatever
        // details we need from that action.
        return state
            .setIn(
                [action.uniqueWidgetId, "messages", action.messageKey, "edits", "grassrootsActionId"],
                action.response.data,
            )
            .setIn([action.uniqueWidgetId, "messages", action.messageKey, "edits", "isCalling"], false)
    },

    [actionTypes.CALL_OFFICE_FAIL](state, action) {
        // the call failed. Set isCalling to false.
        return state.setIn([action.uniqueWidgetId, "messages", action.messageKey, "edits", "isCalling"], false)
    },

    [actionTypes.SET_OFFICIALS_PREVIEW](state, action) {
        return state.setIn([OFFICIALS_PREVIEW], fromJS(action.officials))
    },

    [actionTypes.SET_TARGETED_MESSAGES](state, action) {
        return state.setIn([TARGETED_MESSAGES], fromJS(action.targetedMessages))
    },

    [actionTypes.UPDATE_FORMATTED_TARGETED_MESSAGE](state, action) {
        const path = [TARGETED_MESSAGES, action.groupId]
        return state.setIn(path, fromJS(action.newTargetedMessage))
    },

    [actionTypes.STORE_SUPPORTER_PLACEHOLDER_VALUES](state, action) {
        return state.setIn([action.uniqueWidgetId, SUPPORTER_PLACEHOLDERS], fromJS(action.supporter))
    },

    [actionTypes.REPLACE_TARGETED_MESSAGE_SUPPORTER_NAME_PLACEHOLDERS](state, action) {
        const targetedMessages = state.getIn([TARGETED_MESSAGES], fromJS(action.targetedMessages)).toJS()
        const lastSupporterValues = state.getIn([action.uniqueWidgetId, SUPPORTER_PLACEHOLDERS])?.toJS()
        const targetedMessagesWithPlaceholdersReplaced = replaceSupporterPlaceholderOnTargetedMessages(
            targetedMessages,
            {
                firstname: action.supporter.firstname,
                lastname: action.supporter.lastname,
                address: action.supporter.address,
                email: action.supporter.email,
            },
            lastSupporterValues,
        )
        return state.setIn([TARGETED_MESSAGES], fromJS(targetedMessagesWithPlaceholdersReplaced))
    },
}

export default function campaignFormReducer(state = initialState, action) {
    const returnVal = handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state

    return returnVal
}
