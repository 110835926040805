import React from "react"
import { Campaign } from "@/types/djangio"

import {
    Contents,
    IS_SINGLE_COLUMN_ENABLED,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages"
import { Title } from "@mantine/core"
import {
    PersonalStory,
    StyledButton,
    TitleAndSubtitle,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/InformationContainer.styles"

interface Props {
    campaign: Campaign
    handleChangeContent: (contentName: Contents) => void
}

export const InformationContainer = ({ handleChangeContent, campaign }: Props) => {
    const { name: title, short_description: subtitle, long_description: description } = campaign

    return (
        <PersonalStory>
            <TitleAndSubtitle>
                <Title order={1}>{title}</Title>
                <Title order={3}>{subtitle}</Title>
            </TitleAndSubtitle>
            <p
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            <StyledButton
                isSingleColumnLayout={IS_SINGLE_COLUMN_ENABLED}
                className="send-button"
                onClick={() => handleChangeContent(Contents.questions)}
            >
                GET STARTED
            </StyledButton>
        </PersonalStory>
    )
}
