import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"

import { Field } from "redux-form/immutable"
import { createNumberMask } from "redux-form-input-masks"
import styled from "styled-components"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { runUserJavascript, updateOrCreateStyleTagWithId } from "QuorumGrassroots/helperFunctions"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import UserInfoFormSection from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/index"
import GridToggleField from "app/static/frontend/forms/components/GridToggleField"
import InputField from "app/static/frontend/forms/components/InputField"
import CheckBoxField from "app/static/frontend/forms/components/CheckBoxField"
import InputGroupField from "app/static/frontend/forms/components/InputGroupField"
import Page405 from "QuorumGrassroots/framework/components/405"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { getCharityByEin } from "@/services/charities"
import { CharitySelect, charityApiToOption } from "QuorumGrassroots/widgets/DonationForm/components/CharitySelect"
import DonationTierLabel from "QuorumGrassroots/widgets/DonationForm/components/DonationTierLabel"
import { CheckMailingInstructions } from "QuorumGrassroots/widgets/DonationForm/components/CheckMailingInstructions"

import { Skeleton } from "@mantine/core"
import { useNavigate } from "react-router-dom"
import { api } from "@/api"

const { TransactionMethodType } = DjangIO.app.ledger.types
const { PayrollDonationType } = DjangIO.app.grassroots.types

const PacCharityTitle = styled.p`
    font-size: 18px;
    font-weight: 500 !important;
    color: #000;
    margin: 0;
`

const DisclaimerTextContainer = styled.div`
    margin-top: 12px;
`

const validateCharityAdditionalInfo = (value) => {
    if (value && value.length > 255) {
        return "Additional information content cannot be more than 255 characters."
    }
    return undefined
}

const validateCharity = (charity) =>
    charity?.invalid
        ? "This Charity is no longer available for a match. Please choose a new charity to receive the match."
        : null

const CreditCardIFrame = ({ src, height, width }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [iframeSrc, setIframeSrc] = useState("")
    const navigate = useNavigate()

    const getEadvocacyToken = async (supporterId = window.userdata.id) => {
        try {
            const response = await api.get({
                resourceUri: "/api/pac/eadvocacy-login/",
                action: "get_eadvocacy_login_url",
                params: {
                    supporter_id: supporterId,
                },
            })

            return response.json()
        } catch (err) {
            console.warn("Error while getting PACC eAdvocacy token (logged below)")
            console.error(err)
            return null
        }
    }

    useEffect(() => {
        const fetchTokenAndSetIframe = async () => {
            const token = await getEadvocacyToken()
            if (token) {
                const url = `${src}&${token}`
                setIframeSrc(url)
            }
        }

        fetchTokenAndSetIframe()
    }, [])

    const IframeListener = () => {
        useEffect(() => {
            const messageHandler = (event) => {
                const eAdvocacyOrigin = new URL(src)
                if (event.origin !== eAdvocacyOrigin.origin) {
                    return
                }

                const { action } = event.data
                if (action === "completed") {
                    navigate(`thanks`)
                }
            }

            window.addEventListener("message", messageHandler)

            return () => {
                window.removeEventListener("message", messageHandler)
            }
        }, [navigate])

        return null
    }

    const handleIframeLoad = () => {
        // For some reason the onLoad property on the iframe is not working in Firefox.
        // We need to manually set the loading state to false after a delay.
        if (navigator.userAgent.match(/firefox|iceweasel|fxios/i) !== null) {
            return setTimeout(() => setIsLoading(false), 6000)
        }

        setIsLoading(false)
    }

    return (
        <Skeleton height="100%" radius="md" visible={isLoading}>
            <>
                <IframeListener />
                <iframe
                    src={iframeSrc}
                    height={height}
                    width={width}
                    onLoad={handleIframeLoad}
                    style={{
                        border: "none",
                    }}
                    title="pac-match-iframe"
                />
            </>
        </Skeleton>
    )
}

const DonationAmountSection = ({
    amounts,
    donationAmountOther,
    donateAmountPrompt,
    donationMethod,
    change,
    payrollFrequencyTypeObj,
    isPayrollByPercentageDonation,
    organizationDesign,
    appendNewActionCenterClass,
}) => {
    const handleAmountChange = (amount) => {
        if (donationMethod === TransactionMethodType.payroll.value) {
            change("donationAmountPerYear", amount.originalValue)
            return change("donationAmount", amount.value)
        } else {
            return change("donationAmount", amount)
        }
    }

    const amountChoices = useMemo(() => {
        if (!amounts) return []

        return amounts.map((amount, index) => ({
            value: amount.value,
            label: (
                <DonationTierLabel
                    donationMethod={donationMethod}
                    conditionalGivingLevelLabel={amount.label}
                    value={donationMethod === TransactionMethodType.payroll.value ? amount.value.value : amount.value}
                    index={index}
                    tierType="amount"
                    frequencyLabel={payrollFrequencyTypeObj.label}
                />
            ),
        }))
    }, [amounts, donationMethod])

    return (
        <>
            {!isPayrollByPercentageDonation && donationMethod !== TransactionMethodType.credit_card.value && (
                <>
                    <Field
                        component={GridToggleField}
                        name="donationAmount"
                        customOnChange={handleAmountChange}
                        label={donateAmountPrompt}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__toggle-button--block")}
                        choices={amountChoices}
                        pillWidth={3}
                        allowNull={false}
                        displayErrorWithoutTouch={false}
                    />
                    {donationAmountOther && (
                        <Field
                            component={InputField}
                            name="donationAmountCustom"
                            placeholder="$"
                            dataCy="donation-amount-other"
                            displayErrorWithoutTouch={false}
                            {...createNumberMask({
                                decimalPlaces: 2,
                                multiplier: 100,
                                prefix: "$",
                            })}
                        />
                    )}
                </>
            )}
        </>
    )
}

const PacMatchCharitySection = ({
    shouldDisplayPacMatchFields,
    donationMethod,
    appendNewActionCenterClass,
    pacMatchDescription,
    pacMatchSelectLabel,
    organizationDesign,
    isInitialCharityLoading,
}) => {
    const renderPacMatchDescription = (description) =>
        description && (
            <div>
                <div className="pac-match-description-text" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        )

    return (
        <>
            {shouldDisplayPacMatchFields && donationMethod !== TransactionMethodType.credit_card.value && (
                <div className={appendNewActionCenterClass("new-action-center--pac-charitable-match")}>
                    <PacCharityTitle>PAC charitable match</PacCharityTitle>
                    <div className={appendNewActionCenterClass("new-action-center__section")}>
                        {renderPacMatchDescription(pacMatchDescription)}
                        {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}
                    </div>
                    <Field
                        name="charity"
                        label={pacMatchSelectLabel || "PAC Match Charity"}
                        placeholder="Enter at least 3 characters of the charity name"
                        component={CharitySelect}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__select")}
                        validate={validateCharity}
                        isLoading={isInitialCharityLoading}
                        isClearable
                    />
                    <Field
                        name="pac_charity_additional_info"
                        label="Additional Information"
                        placeholder="E.g. name a specific program to receive charity"
                        component={InputField}
                        validate={validateCharityAdditionalInfo}
                    />
                    <Field
                        name="pac_charity_anonymous"
                        component={CheckBoxField}
                        organizationDesign={organizationDesign}
                        className={appendNewActionCenterClass("new-action-center__checkbox")}
                        buttonText="I want to remain anonymous"
                        dataCy="donation-form-anonymous"
                    />
                </div>
            )}
        </>
    )
}

export const DonationFormWidget = ({
    t,
    canAccess,
    conditionalGivingLevelsEnabled,
    userConditionalGivingLevel,
    amounts,
    initialValues,
    enabledPacMatch,
    pacMatchSelectLabel,
    pacMatchDescription,
    shouldDisplayPayrollDonationTypesField,
    donationMethod,
    allowedPayrollDonationTypesChoices,
    shouldDisplayPacMatchFields,
    donationPercentagesPayroll,
    isPayrollByPercentageDonation,
    organizationDesign,
    payrollFrequencyTypeObj,
    pacClassicSite,
    ...props
}) => {
    useEffect(() => {
        const { customJavascript, customCSS } = props

        if (customJavascript) {
            setTimeout(runUserJavascript(customJavascript))
        }

        if (customCSS) {
            updateOrCreateStyleTagWithId("CustomDonationFormCSS", customCSS)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const isConditionalGivingLevelValid = conditionalGivingLevelsEnabled && userConditionalGivingLevel && amounts

        if (!isConditionalGivingLevelValid) return

        const amountMatchedGivingLevel = amounts?.find((amount) => {
            return amount.label?.trim().toLowerCase() === userConditionalGivingLevel.trim().toLowerCase()
        })

        const percentageMatchedGivingLevel = donationPercentagesPayroll?.find((donationPercentage) => {
            return donationPercentage.label?.trim().toLowerCase() === userConditionalGivingLevel.trim().toLowerCase()
        })

        if (amountMatchedGivingLevel && !isPayrollByPercentageDonation) {
            if (donationMethod === TransactionMethodType.payroll.value) {
                props.change("donationAmount", amountMatchedGivingLevel.value.value)
                props.change("donationAmountPerYear", amountMatchedGivingLevel.value.originalValue)
            } else {
                props.change("donationAmount", amountMatchedGivingLevel.value)
            }
        }

        if (percentageMatchedGivingLevel) {
            props.change("donation_percentage_payroll", percentageMatchedGivingLevel)
            props.change("percentage_label", percentageMatchedGivingLevel.percentage_label)
            props.change("payroll_decimal_value", percentageMatchedGivingLevel.percentage_decimal_value)
        }
    }, [
        conditionalGivingLevelsEnabled,
        userConditionalGivingLevel,
        amounts,
        donationPercentagesPayroll,
        isPayrollByPercentageDonation,
        donationMethod,
    ])

    const [isInitialCharityLoading, setIsInitialCharityLoading] = useState(true)

    useEffect(() => {
        const charityEin = initialValues.get("pac_charity_ein")
        const charityName = initialValues.get("pac_charity_name")
        const additionalInfo = initialValues.get("pac_charity_additional_info")

        if (enabledPacMatch && isInitialCharityLoading) {
            if (charityEin) {
                getCharityByEin(charityEin).then((ein) => {
                    props.change(
                        "charity",
                        ein
                            ? charityApiToOption(ein)
                            : {
                                  label: `${charityName || "Unknown Charity"} (${charityEin})`,
                                  value: charityEin,
                                  invalid: true,
                              },
                    )
                    setIsInitialCharityLoading(false)
                })
            }

            if (additionalInfo) {
                props.change("pac_charity_additional_info", additionalInfo)
            }
        } else {
            setIsInitialCharityLoading(false)
        }
    }, [initialValues, enabledPacMatch, isInitialCharityLoading])

    useEffect(() => {
        if (donationMethod !== TransactionMethodType.payroll.value) {
            props.change("payrollDonationType", null)
            return
        }

        if (
            !shouldDisplayPayrollDonationTypesField &&
            allowedPayrollDonationTypesChoices &&
            allowedPayrollDonationTypesChoices.length
        ) {
            props.change("payrollDonationType", allowedPayrollDonationTypesChoices[0].value)
            return
        }

        props.change("payrollDonationType", PayrollDonationType.amount.value)
    }, [donationMethod, shouldDisplayPayrollDonationTypesField, allowedPayrollDonationTypesChoices])

    const normalizePayrollDonationTypeChoice = (formValue) => {
        if (!formValue || !formValue.value || isNaN(formValue.value)) return null

        return Number(formValue.value)
    }

    const donationPercentagesPayrollChoices = useMemo(() => {
        if (!donationPercentagesPayroll) return []

        return donationPercentagesPayroll.map((donationPercentage, index) => ({
            value: donationPercentage,
            label: (
                <DonationTierLabel
                    conditionalGivingLevelLabel={donationPercentage.label}
                    value={donationPercentage.percentage_label}
                    index={index}
                    frequencyLabel={payrollFrequencyTypeObj.label}
                />
            ),
        }))
    }, [donationPercentagesPayroll])

    const appendNewActionCenterClass = (className) =>
        isFeatureEnabled("ff_pac_action_center_new_design") ? className : ""

    const DonationMethodSection = () => {
        const isSingleChoice = props.allowedDonationMethodChoices && props.allowedDonationMethodChoices.length === 1

        return (
            <>
                <Field
                    component={GridToggleField}
                    name="donationMethod"
                    label={props.donateMethodPrompt}
                    choices={props.allowedDonationMethodChoices}
                    className={
                        appendNewActionCenterClass("new-action-center__toggle-button--inline") +
                        (isSingleChoice
                            ? " " + appendNewActionCenterClass("new-action-center__toggle-button--single-option")
                            : "")
                    }
                    pillWidth={6}
                    allowNull={false}
                />

                {shouldDisplayPayrollDonationTypesField && (
                    <Field
                        component={InputGroupField}
                        name="payrollDonationType"
                        choices={allowedPayrollDonationTypesChoices}
                        className={appendNewActionCenterClass("new-action-center__radio-button-group")}
                        normalize={normalizePayrollDonationTypeChoice}
                        pillWidth={6}
                        allowNull={false}
                    />
                )}

                {isPayrollByPercentageDonation &&
                    donationPercentagesPayrollChoices &&
                    donationPercentagesPayrollChoices.length && (
                        <Field
                            component={GridToggleField}
                            name="donation_percentage_payroll"
                            label={props.donateAmountPrompt}
                            choices={donationPercentagesPayrollChoices}
                            customOnChange={(donationPercentagePayroll) => {
                                props.change("donation_percentage_payroll", donationPercentagePayroll)
                                props.change("percentage_label", donationPercentagePayroll.percentage_label)
                                props.change(
                                    "payroll_decimal_value",
                                    donationPercentagePayroll.percentage_decimal_value,
                                )
                            }}
                            className={appendNewActionCenterClass("new-action-center__toggle-button--block")}
                            pillWidth={3}
                            allowNull={false}
                        />
                    )}

                {props.shouldDisplayCreditCardForm && (
                    <CreditCardIFrame src={pacClassicSite?.site_tab_url} height="600px" width="100%" />
                )}
            </>
        )
    }

    const TermsAndConditionsSection = () => {
        return (
            <>
                {donationMethod !== TransactionMethodType.credit_card.value && (
                    <div
                        className={appendNewActionCenterClass(
                            "new-action-center__section new-action-center__terms-and-conditions",
                        )}
                    >
                        {isFeatureEnabled("ff_pac_action_center_new_design") && (
                            <div class="control-label form-label new-action-center">Terms and conditions</div>
                        )}
                        {props.disclaimerText && (
                            <DisclaimerTextContainer className="disclaimer">
                                <div
                                    className="disclaimer-text"
                                    dangerouslySetInnerHTML={{ __html: props.disclaimerText }}
                                />
                                {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}
                            </DisclaimerTextContainer>
                        )}
                        {!isFeatureEnabled("ff_pac_action_center_enhancements_terms_and_conditions") ? (
                            <Field
                                name="agreeTermsConditions"
                                component={CheckBoxField}
                                organizationDesign={organizationDesign}
                                className={appendNewActionCenterClass("new-action-center__checkbox")}
                                buttonText="I agree to terms and conditions" // TODO: i18n
                                dataCy="donation-form-terms-agree"
                            />
                        ) : (
                            isFeatureEnabled("ff_pac_action_center_enhancements_terms_and_conditions") &&
                            props.termsAndConditionsSwitch && (
                                <Field
                                    name="agreeTermsConditions"
                                    component={CheckBoxField}
                                    organizationDesign={organizationDesign}
                                    className={appendNewActionCenterClass("new-action-center__checkbox")}
                                    buttonText="I agree to Terms and Conditions" // TODO: i18n
                                    dataCy="donation-form-terms-agree"
                                />
                            )
                        )}
                    </div>
                )}
            </>
        )
    }

    const SubmitValueSection = () => {
        return (
            <>
                {donationMethod !== TransactionMethodType.credit_card.value && (
                    <div className={appendNewActionCenterClass("new-action-center__button-wrapper")}>
                        <StyledButton
                            onClick={props.handleSubmit}
                            disabled={props.submitting}
                            className={appendNewActionCenterClass("new-action-center__button")}
                            type="submit"
                            data-cy="donation-form-submit"
                            shouldUseAutoColor
                        >
                            {
                                props.submitting ? t("form.submitting") : props.submitButtonText || "Donate Now" // TODO: i18n
                            }
                        </StyledButton>
                    </div>
                )}
            </>
        )
    }

    const renderMain = () => (
        <WidgetStyleWrapper
            className={"donation-form-widget " + appendNewActionCenterClass("new-action-center")}
            md={props.colWidth}
            useWidgetStyling={props.useWidgetStyling}
            title={props.title}
        >
            <UserInfoFormSection registrationPageIds={props.listDonationFormId} t={t} showOnlyUnfilledFields={false} />

            <DonationMethodSection />

            <DonationAmountSection
                amounts={amounts}
                donationAmountOther={props.donationAmountOther}
                donateAmountPrompt={props.donateAmountPrompt}
                donationMethod={donationMethod}
                change={props.change}
                payrollFrequencyTypeObj={payrollFrequencyTypeObj}
                isPayrollByPercentageDonation={isPayrollByPercentageDonation}
                organizationDesign={organizationDesign}
                appendNewActionCenterClass={appendNewActionCenterClass}
            />

            {props.shouldDisplayCheckMailingInstructions && (
                <CheckMailingInstructions donationFormId={props.donationFormId} />
            )}

            <PacMatchCharitySection
                shouldDisplayPacMatchFields={shouldDisplayPacMatchFields}
                donationMethod={donationMethod}
                appendNewActionCenterClass={appendNewActionCenterClass}
                pacMatchDescription={pacMatchDescription}
                pacMatchSelectLabel={pacMatchSelectLabel}
                organizationDesign={organizationDesign}
                isInitialCharityLoading={isInitialCharityLoading}
            />

            <TermsAndConditionsSection />

            {!isFeatureEnabled("ff_pac_action_center_new_design") && <br />}

            <SubmitValueSection />
        </WidgetStyleWrapper>
    )

    if (canAccess) {
        return renderMain()
    } else {
        return <Page405 errorHtml={props.cannotAccessMessage} compact />
    }
}

DonationFormWidget.propTypes = {
    donationFormId: PropTypes.number.isRequired,
    allowedDonationMethodChoices: PropTypes.array.isRequired,
    allowedPayrollDonationTypesChoices: PropTypes.array.isRequired,
    cannotAccessMessage: PropTypes.string,
    donateMethodPrompt: PropTypes.string.isRequired,
    donateAmountPrompt: PropTypes.string.isRequired,
    amounts: PropTypes.object,
    colWidth: PropTypes.number,
    donationAmountOther: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    listDonationFormId: PropTypes.array.isRequired,
    shouldDisplayCreditCardSite: PropTypes.bool.isRequired,
    shouldDisplayPayrollDonationTypesField: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    useWidgetStyling: PropTypes.bool,
    conditionalGivingLevelsEnabled: PropTypes.bool,
    change: PropTypes.func.isRequired,
    shouldDisplayPacMatchFields: PropTypes.bool.isRequired,
    shouldDisplayCheckMailingInstructions: PropTypes.bool.isRequired,
    customJavascript: PropTypes.string,
    customCSS: PropTypes.string,
    termsAndConditionsSwitch: PropTypes.bool,
    customAfterRegistrationJavascript: PropTypes.string,
    organizationDesign: PropTypes.object,
    pacClasssicSite: PropTypes.object,
}

DonationFormWidget.defaultProps = {
    useWidgetStyling: true,
}

export default DonationFormWidget
